import React from 'react'
import '../src/components/style.css'
import '../src/components/assets/css/bootstrap.min.css'
import '../src/components/assets/css/meanmenu.min.css'
import '../src/components/assets/css/responsive.css'
import '../src/components/venobox/venobox.css'
import '../src/components/assets/css/owl.transitions.css'
import '../src/components/assets/css/owl.carousel.min.css'
import '../src/components/assets/css/all.min.css'
import '../src/components/assets/css/animated-text.css'
import '../src/components/assets/css/animate.css'
import '../src/components/assets/css/flaticon.css'
import '../src/components/assets/css/theme-default.css'
import '../src/components/assets/css/meanmenu.min.css'
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import Footer from './pages/Footer'
import Homepage from './pages/Homepage';
import Scrollup from './pages/Scrollup'
import Aboutus from './pages/Aboutus'
import Contact from './pages/Contact'
import Service from './pages/Service'
import Packages from './pages/Pacakges'
import Refundpolicy from './pages/Refundpolicy'
import Termscondition from './pages/Termscondition'
import PrivcyPolicy from './pages/PrivcyPolicy'
import Navbar from './pages/Navbar'
function App() {

  


  return (
    <div className="App">

<BrowserRouter >
<Navbar/>

<Routes>
<Route path='/' element={<Homepage/>} />
<Route path='/Aboutus' element={<Aboutus/>} />
<Route path='/Contact' element={<Contact/>} />
<Route path='/Service' element={<Service/>} />
<Route path='/Packages' element={<Packages/>}/>
<Route path='/Refundpolicy' element={<Refundpolicy/>}/>
<Route path='/Termscondition' element={<Termscondition/>}/>
<Route path='/PrivcyPolicy' element={<PrivcyPolicy/>}/>
<Route path='*' element={<Homepage/>}/>

</Routes>

<Scrollup/>
<Footer/>
</BrowserRouter>



    </div>
  );
}

export default App;
