import React from 'react'
import {BiPlus} from 'react-icons/bi'

import 'react-circular-progressbar/dist/styles.css';

import {Link} from 'react-router-dom'

import Img38 from '../components/assets/images/3dlogoicon.png'
const Service = () => {


  return (
    <div>
        <div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Packages </h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Packages </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Packages </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="service-area">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-6">
				<div class="itsoft-section-title pb-90">
					<h2> We Run All Kinds Of Packages From <span> Technologies </span></h2>
				</div>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="itsoft-button text-right pt-20">
					<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6bnVsbCwicmVxdWVzdFNoaXBwaW5nSW5mbyI6IlllcyIsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImMyNWRiYzU4YWEwNDMyMjM4ZjZmMDQyZDgzYzI1NzFhIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwic2hpcHBpbmdJbmZvIjp7InNoaXBwaW5nQ291bnRyeSI6bnVsbCwic2hpcHBpbmdBZGRyZXNzMSI6IiIsInNoaXBwaW5nQWRkcmVzczIiOiIiLCJzaGlwcGluZ0FkZHJlc3MzIjoiIiwic2hpcHBpbmdDaXR5IjoiIiwic2hpcHBpbmdTdGF0ZSI6bnVsbCwic2hpcHBpbmdaaXAiOiIifX0%3D&amount=2.99&feeType=amount" > Buy Trial <BiPlus/> </a>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 1 <br/> (1 Month)</h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<h4> 15.99							</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=15.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 2 <br/>(1 Month)
 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<h4>$29.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=29.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 3 <br/>(1 Month)
 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<h4>$57.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=57.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 4 <br/>(1 Month)
 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$69.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=69.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div><div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 1 <br/>(3 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<h4>$45.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=45.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 2 <br/>(3 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<h4>$89.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=89.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 3 <br/>(1 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<h4>$174.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=174.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 4 <br/>(3 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$219.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=219.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>Service 1 <br/>(6 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<h4>$69.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=69.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 2 <br/>(6 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<h4>$139.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=139.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 3 <br/>(6 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<h4>$269.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=269.99&feeType=amount"> Buy Now<BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 4 <br/>(6 Month)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$329.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=329.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>Service 1 <br/>(1 Year)

 </h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<h4>$109.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=109.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 2 <br/>(1 Year)

 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<h4>$189.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=189.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 3 <br/>(1 Year)

 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<h4>$349.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=349.99&feeType=amount"> Buy Now<BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 4 <br/>(1 Year)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$439.99</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=439.99&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>


			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>Service 1 <br/>(Lifetime)

 </h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<h4>$500</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=500&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 2 <br/>(Lifetime)

 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<h4>$600</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=600&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 3 <br/>(Lifetime)

 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<h4>$700</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=700&feeType=amount"> Buy Now<BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img38} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Service 4 <br/>(Lifetime)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$800</h4>
						</div>
						<div class="service-button">
							<a href="https://awquiredashboard.com/gateway/public/form?data=eyJkYmFJZCI6IjIyMDE5MiIsInRlcm1pbmFsSWQiOiI0NzgyMzEiLCJ0aHJlZWRzIjoiRGlzYWJsZWQiLCJyZXR1cm5VcmwiOiIiLCJyZXR1cm5VcmxOYXZpZ2F0aW9uIjoidG9wIiwibG9nbyI6bnVsbCwidmlzaWJsZU5vdGUiOm51bGwsInJlcXVlc3RDb250YWN0SW5mbyI6IlllcyIsInJlcXVlc3RCaWxsaW5nSW5mbyI6IlllcyIsInJlcXVlc3RTaGlwcGluZ0luZm8iOm51bGwsInNlbmRSZWNlaXB0IjpudWxsLCJvcmlnaW4iOiJIb3N0ZWRGb3JtIiwiaGFzaCI6ImNhNTU5N2I0ZjhlODZhNTA4NzJjZDZmMDhhMzRkMTdiIiwiY29udGFjdEluZm8iOnsiY29udGFjdE5hbWUiOiIiLCJjb250YWN0RW1haWwiOiIiLCJjb250YWN0UGhvbmUiOiIifSwiYmlsbGluZ0luZm8iOnsiYmlsbGluZ0NvdW50cnkiOiIiLCJiaWxsaW5nU3RyZWV0IjoiIiwiYmlsbGluZ1N0cmVldDIiOiIiLCJiaWxsaW5nQ2l0eSI6IiIsImJpbGxpbmdTdGF0ZSI6IiIsImJpbGxpbmdaaXAiOiIifX0%3D&amount=800&feeType=amount"> Buy Now <BiPlus/> </a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
		<h4>This is Third Party Payment Solution</h4>
			</div>
			<div class="col-lg-6 col-md-6 text-lg-right">
			<h4> You can also make payments over the phone. 
				<br/>
				3462203746
				<br/>
				3322527986
			</h4>
			</div>
			
		</div>
	</div>
</div>











</div>
  )
}

export default Service